header {
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max-width); /* Max width for all content */
    min-width: var(--min-width);
    margin: 0 auto;
    padding: 0 20px;
}

header .logo img {
    height: 70px;
}

nav {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

header nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

header nav ul li {
    margin: 0 0px;
}

header nav ul li a {
    color: rgb(39, 28, 28);
    text-decoration: none;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s, border-bottom 0.3s; /* Add transition for smooth animation */
}

.header-link {
    font-size: 1.1rem;
    font-weight: 600;
}

header nav ul li a:hover {
    border-bottom: 1px solid #052907; /* Add underline effect on hover */
}

/* Membership Login button */
.login-button {
    margin-left: 20px;
    border-radius: 25px; /* Add rounded corners */
    display: inline-flex; /* Allow wrapping and centering */
    align-items: center; /* Center content vertically */
    padding: 10px 20px;
    background-color: #77836a;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2rem;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.login-button:hover {
    background-color: #1e2e0d;
}

/* Center the text within the button */
.login-button a {
    display: inline-block; /* Allow wrapping */
    text-align: center; /* Center text horizontally */
    width: 100%; /* Ensure the text takes full width */
}

@media (min-width: 769px){
    .hamburger { display: none; }
}
@media (max-width: 768px) {
    header .container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    header nav ul {
      flex-direction: column;
      align-items: flex-start;
      width: 80%;
      display: none; /* Hide the menu by default */;
    }
  
    header nav ul.show {
      display: flex; /* Show the menu when toggled */
    }
  
    header nav ul li {
      margin: 10px 0;
    }
  
    .hamburger {
      display: block;
      cursor: pointer;
      font-size: 24px;
      padding: 12px 16px;
      width: 2rem;
      align-items: right;
    }
  }