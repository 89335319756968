/* Application Layout */
* {
  box-sizing: "border-box";
    /* min-height: 100vh; */
  --max-width: 1200px;
  --min-width: 100px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.columns {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

main {
  flex-grow: 1;
  width: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f1f0e7;
  scroll-behavior: smooth;
  margin: 0;
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
}

/* Set max with of content inside divs */
.container-global {
  min-width: var(--min-width);
}

/* Needed to add space between content on small screen sizes */
.wrapper-global {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 1rem;
}

.content-section {
  background: #fff;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 3rem 0;
}

/* Element Global Settings */

.flex {display: flex;}
a { text-decoration: none;}

h1 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  width: 100%;
  font-size: 4.5rem;
}

 h2 {
  padding-top: 0px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 2.5rem;
  text-wrap: true;
  display: block;
  width: 100%;
}

/* To Migrate Away */

.features, .events, .content-section {
  margin: 20px 0;
  background: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.features h3, .events h3, .content-section h2 {
  color: #77836a;
}

.features ul {
  list-style: none;
}

.features ul li {
  margin: 10px 0;
}

.events p, .content-section p {
  color: #555;
}

/* Footer */

.instagram-link {
  color: #E1306C; /* Instagram's color */
  font-size: 24px; /* Adjust the size as needed */
  text-decoration: none;
}

.instagram-link:hover {
  color: #C13584; /* Slightly different color on hover */
}
