body {
  font-family: Arial, sans-serif;
}

.right-title {
  text-align: left;
  width: 100%;
}

.pricing-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.pricing-table th, .pricing-table td {
  border: 1px solid #ddd;
  padding: 16px;
  text-align: center;
}
.pricing-table th {
  background-color: #666;
  color: white;
  font-size: 1.2em;
}
.plan-title {
  font-size: 1.5em;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
  padding: 10px 0;
}
.plan-title.popular {
  position: relative;
}
/* .plan-title.popular::after {
  content: "Most Popular";
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #FFCC00;
  color: black;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 0.8em;
} */
.price {
  font-size: 1.2em;
  color: #4CAF50;
}
.feature {
  text-align: left;
  background-color: #f2f2f2;
}
.feature-check {
  color: #4CAF50;
}
.feature-row:nth-child(even) {
  background-color: #fff;
}
.feature-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.price-notice {
  margin-top: 10px;
  font-size: 1.2em;
  color: #555; /* Grey color for the notice text */
}

.price-notice strong {
  color: #d32f2f; /* Red color for the regular price */
}

.membership-limited {
  margin-top: 20px; /* Additional spacing for better readability */
  font-size: 1.1em;
  color: #000; /* Black color for the limited membership text */
}

.membership-join {
  background: #77836a;
  color: white;
  font-weight: bold;
  border: none;
  padding: 15px 25px;
  font-size: 1.2em;
  margin: 10px;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s;
}

.membership-join button:hover {
  background: #1e2e0d;
}