.slider-container {
  max-width: 500px;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slick-prev, .slick-next {
  z-index: 1;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #2e7d32;
}

/* Media Queries for responsive design */
@media (max-width: 768px) {
  .slider-container {
    max-width: 100%;
  }

  .slick-slide img {
    width: 50%; /* Adjust width for smaller screens */
    margin: 0 auto; /* Center the image */
  }
}