
.hero {
    text-align: left;
    background: url('./../../assets/golf-bays.jpg') no-repeat center center/cover;
    color: white;
    padding: 100px 20px;
    align-items: center;
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  
button {
background: #77836a;
color: white;
font-weight: bold;
border: none;
padding: 15px 25px;
font-size: 1.2em;
margin: 10px;
display: block;
width: 100%;
cursor: pointer;
border-radius: 25px;
transition: background-color 0.3s, color 0.3s;
}
  
  button:hover {
    background: #1e2e0d;
  }
  