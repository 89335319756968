footer {
    background: #77836a;
    color: white;
    text-align: center;
    padding: 1em 0;
    width: 100%;
    bottom: 0;
    height: 8em;
  }
  
  footer .social-media a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }