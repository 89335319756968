/* Styles for About component */


.location-section {
  display: flex;
  align-items: center;
  margin: 40px 0;
  width: 100%;
}

.location-text {
  padding-right: 0px;
  flex-grow: 1;
}

.location-image {
  flex-grow: 1;
}

.location-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .location-section{
    display: block;
  }
}